import { FormDefinition, getRoleList } from '@cksoftware/react-base';
import { PropertyDisplayType } from '../../../enums/form/PropertyDisplayType';
import { ValtUser } from '../../../globalModels/user/valtUser';

export const EditUserFormDefinition: FormDefinition<ValtUser> = {
  questions: [
    { key: 'Email', label: 'Email' },
    { key: 'FirstName', label: 'First Name' },
    { key: 'LastName', label: 'Last Name' },
    { key: 'IsDisabled', label: 'Disabled', propertyDisplayType: PropertyDisplayType.BooleanRadio, controlStyleProps: { w: '50%' } },
    { key: 'IsLockedOut', label: 'Locked Out', propertyDisplayType: PropertyDisplayType.BooleanRadio, controlStyleProps: { w: '50%' } },
    { key: 'TwoFactorEnabled', label: '2FA Enabled', propertyDisplayType: PropertyDisplayType.BooleanRadio, controlStyleProps: { w: '50%' } },
    {
      key: 'Roles',
      label: 'Roles',
      propertyDisplayType: PropertyDisplayType.MultiSelect,
      propertyDisplayTypeConfig: {
        isNumeric: false,
        itemFetch: async () => {
          const data = await getRoleList();
          return data.map((role) => {
            return { Value: role.Name, Name: role.Name };
          });
        }
      }
    }
  ],
  entityName: 'EditValtUserForm'
};
