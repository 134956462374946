import { Box, Center, Flex, Heading, Text } from '@chakra-ui/react';

export const FunnelComplete = () => {
  return (
    <Flex direction={'column'} flex='1' w={'100%'} maxW={'100%'} paddingTop={['30px', '0px']}>
      <Center>
        <Box w={['100%', '100%', '100%', '75%', '75%']}>
          <Heading>Thanks!</Heading>
          <Text>An agent from our team will be reaching out soon.</Text>
          <Text>Please check your email for instructions on setting up your account.</Text>
          <br />
          <Text>-The Valt Team</Text>
        </Box>
      </Center>
    </Flex>
  );
};
