import { Flex, Text } from '@chakra-ui/react';
import { DisplayProperty } from '../models/DisplayProperty';

type PropertyInfoSectionProps = {
  propertiesToDisplay: DisplayProperty[];
  header: string;
};

export const PropertyInfoSection = (props: PropertyInfoSectionProps) => {
  return (
    <>
      <Text fontSize={'xx-large'} color={'green.100'}>
        {props.header}
      </Text>
      <Flex gap={5} justifyContent={'space-between'} flexWrap={'wrap'}>
        {props.propertiesToDisplay
          .filter((r) => !(r.valueList && r.valueList.length == 0))
          .map((value, index) => {
            return (
              <Flex w={{ base: '40%', xl: '20%' }} flexDir={'column'} key={index}>
                <Text fontWeight={'bold'}>{value.label}</Text>
                {value.value ||
                  (value.value == 0 && (
                    <Text>
                      {value.value} {value.secondaryValue}
                    </Text>
                  ))}
                {!value.value && !value.valueList && value.value != 0 && <Text>Not Provided</Text>}
                {value.valueList &&
                  value.valueList.map((val, key) => {
                    return <Text key={key}>{val}</Text>;
                  })}
              </Flex>
            );
          })}
      </Flex>
    </>
  );
};
