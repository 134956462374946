import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const square = defineStyle({
  field: {
    borderRadius: 0,
    border: '1px solid rgb(226, 232, 240)',
    backgroundColor: 'white'
  }
});

export const InputTheme = defineStyleConfig({
  variants: { square }
});
