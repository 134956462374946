import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const funnel = defineStyle({
  marginTop: '30px',
  marginBottom: '20px',
  fontWeight: 'medium'
});

const green = defineStyle({
  fontWeight: 'bolder',
  color: '#1C9A4A'
});

export const HeadingTheme = defineStyleConfig({
  variants: { funnel, green },
  defaultProps: { variant: 'funnel' }
});
