import { Box, Heading, Text } from '@chakra-ui/react';
import { FormDefinitionForm, ResetPasswordModel, getResetPasswordFormDefinition, useFormDefinitionForm } from '@cksoftware/react-base';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PasswordRegex, PasswordRequirementString } from '../../constants/passwordRequirements';
import { useValtAuth } from '../../util/useValtAuth';

export const ConfirmEmail = () => {
  const auth = useValtAuth();
  const entityForm = useFormDefinitionForm(getResetPasswordFormDefinition(PasswordRegex, PasswordRequirementString));
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onSubmit = async (model: ResetPasswordModel) => {
    try {
      await auth.resetPassword(searchParams.get('username'), searchParams.get('token'), model.Password);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response.data && e.response.status == 400) {
        entityForm.methods.setError('Password', { message: PasswordRequirementString });
        entityForm.methods.setError('ConfirmPassword', { message: PasswordRequirementString });
      }
      return;
    }
    await auth.login({
      Email: searchParams.get('username'),
      Password: model.Password
    });
    navigate('/portal');
  };

  return (
    <Box>
      <Heading>You're set!</Heading>
      <Text>Thanks for confirming your email. Enter a password below to finish setting up your account.</Text>
      <FormDefinitionForm
        formDefinitionForm={entityForm}
        onSubmit={(model) => {
          onSubmit(model);
        }}
      />
    </Box>
  );
};
