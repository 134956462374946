import { numberInputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    borderRadius: 0,
    border: '1px solid rgb(226, 232, 240)',
    backgroundColor: 'white'
  }
});

export const numberInputTheme = defineMultiStyleConfig({
  baseStyle
});
