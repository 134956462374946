import { Box, Heading } from '@chakra-ui/react';
import { FormDefinitionForm, LoginFormDefinition, LoginModel, LoginRequest, useFormDefinitionForm } from '@cksoftware/react-base';
import { useNavigate } from 'react-router-dom';
import { useValtAuth } from '../../util/useValtAuth';

export const Login = () => {
  const auth = useValtAuth();
  const entityForm = useFormDefinitionForm(LoginFormDefinition);
  const navigate = useNavigate();

  const onLogin = async (model: LoginModel) => {
    const loginRequest: LoginRequest = {
      Email: model.Email,
      Password: model.Password
    };
    const isSuccess = await auth.login(loginRequest);
    if (isSuccess) {
      navigate('/portal');
    } else {
      entityForm.methods.setError('Password', { message: 'Invalid email or password' });
      entityForm.methods.setError('Email', { message: 'Invalid email or password' });
    }
  };

  return (
    <Box padding={'10px'}>
      <Heading>Login to your VALT account.</Heading>
      <FormDefinitionForm
        formDefinitionForm={entityForm}
        onSubmit={(model) => {
          onLogin(model);
        }}
      />
    </Box>
  );
};
