import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const green = defineStyle({
  backgroundColor: 'secondaryGreen.100',
  border: '1px solid #005837',
  textAlign: 'center',
  borderRadius: '0px',
  width: '200px',
  color: 'white',
  textTransform: 'uppercase',
  _hover: {
    backgroundColor: 'green.50'
  },
  _active: {
    backgroundColor: 'green.100'
  }
});

const gold = defineStyle({
  backgroundColor: 'gold.100',
  border: '1px solid ##B6B6B6',
  textAlign: 'center',
  borderRadius: '0px',
  width: '200px',
  color: 'white',
  textTransform: 'uppercase',
  _hover: {
    backgroundColor: 'gold.50'
  },
  _active: {
    backgroundColor: 'gold.100'
  }
});

const grey = defineStyle({
  backgroundColor: '#E1E1E1',
  _hover: {
    backgroundColor: '#B6B6B6'
  },
  border: '1px solid ##E1E1E1',
  textAlign: 'center',
  borderRadius: '2px',
  color: 'black',
  fontWeight: 'normal',
  width: '100px'
});

export const ButtonTheme = defineStyleConfig({
  variants: { green, gold, grey, secondary: gold, primary: green },
  defaultProps: { variant: 'green' }
});
