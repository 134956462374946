import { useState } from 'react';

type JsonViewerProps = {
  object: any;
};
export const JsonViewer = (props: JsonViewerProps) => {
  const [collapse, setCollapse] = useState(true);
  return (
    <div>
      <div
        onClick={() => {
          setCollapse(!collapse);
        }}>
        Toggle Json View
      </div>
      {!collapse && <pre>{JSON.stringify(props.object, null, 2)}</pre>}
    </div>
  );
};
