import { useQuery } from '@tanstack/react-query';
import { getAxios } from '../../../util/axios';
import { HomeListingFilterModel } from '../models/HomeListingFilterModel';
import { HomeListingResponseModel } from '../models/HomeListingResponseModel';

const getHomeListing = async (filterModel: HomeListingFilterModel): Promise<HomeListingResponseModel[]> => {
  return (await getAxios().get(`/portal/homelisting`, { params: filterModel })).data;
};

export const useHomeListingQuery = (filterModel: HomeListingFilterModel, queryKey: string) => {
  return useQuery({
    queryKey: [queryKey, filterModel],
    placeholderData: (prev) => {
      return prev;
    },
    enabled: filterModel != undefined && filterModel.RangeKm != undefined,
    queryFn: () => getHomeListing(filterModel),
    retry: false
  });
};
