import { useAuth } from '@cksoftware/react-base';
import { useEffect } from 'react';
import { ErrorContainer } from './ErrorContainer';
import { ValtUser } from './globalModels/user/valtUser';
import { AppRoutes } from './routes';

export const App = () => {
  const { initUser, hasLoaded } = useAuth<ValtUser>();

  useEffect(() => {
    async function initAuth() {
      await initUser();
    }
    initAuth();
  }, []);

  return <ErrorContainer>{hasLoaded && <AppRoutes />}</ErrorContainer>;
};
