import { Box, HStack, Icon, Link, Text, VStack } from '@chakra-ui/react';

import { formatCurrency } from '@cksoftware/react-base';
import { IconBath, IconBed, IconRuler } from '@tabler/icons-react';
import { HomeListingResponseModel } from '../../models/HomeListingResponseModel';

type HomeListingMapMarkerInfoProps = {
  property: HomeListingResponseModel;
};

export const HomeListingMapMarkerInfo = ({ property }: HomeListingMapMarkerInfoProps) => {
  return (
    <Box>
      <VStack alignItems={'flex-start'} gap={0}>
        <Box>
          <VStack gap={0} alignItems={'flex-start'}>
            <Text fontWeight={'bold'}> {property.PropertyListingResponse.UnparsedAddress}</Text>
            <Text>
              {property.PropertyListingResponse.City}, {property.PropertyListingResponse.StateOrProvince}
            </Text>
            <Text>{formatCurrency(property.PropertyListingResponse.ListPrice)}</Text>
          </VStack>
        </Box>
        <Box>
          <HStack>
            <Icon as={IconBath} /> <Text>{property.PropertyListingResponse.BathroomsTotalInteger}</Text>
            <Icon as={IconBed} /> <Text>{property.PropertyListingResponse.BedroomsTotal}</Text>
            <Icon as={IconRuler} /> <Text>{Math.round(property.PropertyListingResponse.AboveGradeFinishedArea)}</Text>
          </HStack>
        </Box>
        <Box>
          <Link
            onClick={() => {
              window.open(`/portal/details?propertyId=${property.PropertyListingResponse.ListingKey}`, '_blank');
            }}>
            View Details
          </Link>
        </Box>
      </VStack>
    </Box>
  );
};
