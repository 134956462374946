import { Box } from '@chakra-ui/react';

import { Outlet } from 'react-router-dom';
import { Footer } from './footer';
import { NavBar } from './navBar';

export const WideLayout = () => {
  return (
    <Box as='section' minHeight={'100vh'} display={'flex'} flexFlow={'column'} overflow={'hidden'}>
      <NavBar />
      <Box flex={'1 1 auto'} overflow={'hidden'} w={'100%'}>
        <Box overflow={'hidden'} h={'100%'}>
          <Outlet />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
