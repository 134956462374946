import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ButtonTheme } from '../theme/button';
import { FormLabelTheme } from '../theme/formLabel';
import { HeadingTheme } from '../theme/heading';
import { InputTheme } from '../theme/inputs';

import { numberInputTheme } from '../theme/number';
import { App } from './app';
import { getAxios } from './util/axios';
(window as any).getAxios = getAxios;

const gold = (alpha: number) => {
  return `rgba(198,171,96,${alpha})`;
};

const green = (alpha: number) => {
  return `rgba(0,88,55,${alpha})`;
};

// Version 2: Using functions
const overrides = extendTheme({
  components: {
    Button: ButtonTheme,
    FormLabel: FormLabelTheme,
    Heading: HeadingTheme,
    Input: InputTheme,
    NumberInput: numberInputTheme,
    Progress: {
      defaultProps: {
        colorScheme: 'secondary'
      }
    }
  },
  fonts: {
    heading: `all-round-gothic`,
    body: `all-round-gothic`
  },
  colors: {
    radioButton: {
      selected: green(1),
      unselected: '#D5E9E7',
      border: green(1)
    },
    secondaryGreen: {
      100: '#519087',
      200: '#D5E9E7'
    },
    green: {
      500: green(1),
      400: green(1),
      100: green(1),
      900: green(1),
      50: green(0.5),
      25: green(0.25),
      15: green(0.15)
    },
    primary: {
      500: green(1),
      400: green(1),
      100: green(1),
      900: green(1),
      50: green(0.5),
      25: green(0.25),
      15: green(0.15)
    },
    gold: {
      100: gold(1),
      200: gold(1),
      300: gold(1),
      400: gold(1),
      500: gold(1),
      600: gold(1),
      700: gold(1),
      800: gold(1),
      900: gold(1),
      50: gold(0.5),
      25: gold(0.25),
      15: gold(0.15)
    },
    secondary: {
      100: gold(1),
      200: gold(1),
      300: gold(1),
      400: gold(1),
      500: gold(1),
      600: gold(1),
      700: gold(1),
      800: gold(1),
      900: gold(1),
      50: gold(0.5),
      25: gold(0.25),
      15: gold(0.15)
    }
  },
  styles: {
    global: {
      // styles for the `body`
      body: {}
    }
  }
});
const container = document.getElementById('root')!;
const root = createRoot(container);
const queryClient = new QueryClient();

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={overrides} cssVarsRoot='body'>
        <App />
      </ChakraProvider>
    </QueryClientProvider>
  </BrowserRouter>
);
