import { Box, Button, Card, CardBody, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LoadingSpinner } from '../../../components/misc/LoadingSpinner';
import { useHomeDetailsQuery } from '../api/useHomeDetailsQuery';
import { EmailRequestFormModel } from '../models/EmailRequestForm';
import { HomeListingResponseModel } from '../models/HomeListingResponseModel';
import { EmailRequestModal } from './EmailRequestModal';
import { HomeBasicInfo } from './HomeBasicInfo';
import { HomeDetailsMediaDisplay } from './HomeDetailsMediaDisplay';
import { PropertyDetails } from './PropertyDetails';

export type HomeDetailsPageState = {
  homeDetails: HomeListingResponseModel;
};

export const HomeDetails = () => {
  const [listingKey, setListingKey] = useState<string>();
  const [searchParams] = useSearchParams();

  const homeDetailsQuery = useHomeDetailsQuery(listingKey);
  const emailDisclosure = useDisclosure();
  const [emailModel, setEmailModel] = useState<EmailRequestFormModel>();

  useEffect(() => {
    setListingKey(searchParams.get('propertyId'));
  }, [searchParams]);

  const virtualTourLink = useMemo(() => {
    if (!homeDetailsQuery.data) {
      return undefined;
    }
    const tour = homeDetailsQuery.data.PropertyListingResponse?.Media?.filter((val) => val.MediaCategory == 'Video Tour Website');
    if (!tour || tour.length == 0) {
      return undefined;
    }
    return tour[0].MediaURL;
  }, [homeDetailsQuery.data?.PropertyListingResponse?.Media]);

  if (homeDetailsQuery.isFetching || !homeDetailsQuery.data) {
    return <LoadingSpinner />;
  }
  return (
    <Box w={['100%']} margin={'auto'} overflow={'auto'}>
      <EmailRequestModal isOpen={emailDisclosure.isOpen} onClose={emailDisclosure.onClose} model={emailModel} />
      <Flex flexWrap={'wrap'} gap={3}>
        <HomeDetailsMediaDisplay media={homeDetailsQuery.data.PropertyListingResponse.Media} />
        <Card w={'100%'}>
          <CardBody padding={2} margin={0}>
            <Flex flexDir={'row'} justifyContent={'space-between'}>
              <HomeBasicInfo homeDetailsModel={homeDetailsQuery.data} />
              <Flex flexDir={'column'} gap={4}>
                {virtualTourLink && (
                  <Button
                    onClick={() => {
                      window.open(virtualTourLink, '_blank');
                    }}>
                    Virtual Tour
                  </Button>
                )}
                <Button
                  onClick={() => {
                    setEmailModel({ Message: `I would like to book a showing for property ${listingKey}.`, PreferredContactMethod: undefined });
                    emailDisclosure.onOpen();
                  }}
                  variant='gold'>
                  Book A Showing
                </Button>
                <Button
                  onClick={() => {
                    setEmailModel({ Message: `I have a question regarding property ${listingKey}.`, PreferredContactMethod: undefined });
                    emailDisclosure.onOpen();
                  }}>
                  Ask A Question
                </Button>
              </Flex>
            </Flex>
          </CardBody>
        </Card>

        <Card w={'100%'}>
          <CardBody>
            <Flex flexDir={'column'}>
              <Box>
                <Text fontSize={'xx-large'} color={'green.100'}>
                  Listing Description
                </Text>
                <Text>{homeDetailsQuery.data.PropertyListingResponse.PublicRemarks}</Text>
              </Box>
              <PropertyDetails model={homeDetailsQuery.data} />
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </Box>
  );
};
