import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { HiddenField, PropertyListValueModel } from '@cksoftware/react-base';
import { CreatableSelect } from 'chakra-react-select';
import { useMemo, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { UseFormReturn, useWatch } from 'react-hook-form';

type MultiAddressFieldProps = {
  onSelectedOption: (value: PropertyListValueModel, place: google.maps.places.PlaceResult) => void;
  placeHolder: string;
  addressSelectedField: string;
  methods: UseFormReturn<any, any, any>;
  selectedAddressField: string;
};

export const MultiAddressField = (props: MultiAddressFieldProps) => {
  const [value, setValue] = useState<PropertyListValueModel>();
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);
  const [searchString, setSearchString] = useState(props.methods.getValues(props.selectedAddressField) ?? props.methods.getValues('MlsNumber') ?? '');
  const components = {
    DropdownIndicator: null
  };
  const cityPlaces = usePlacesService({
    apiKey: 'AIzaSyC_v1ZrbLVg23F0yZURTouTjdPOo3nCNTY'
  });

  const addressPlaces = usePlacesService({
    apiKey: 'AIzaSyC_v1ZrbLVg23F0yZURTouTjdPOo3nCNTY'
  });

  const combinedResults = useMemo<PropertyListValueModel[]>(() => {
    const combined = [...cityPlaces.placePredictions, ...addressPlaces.placePredictions];
    let toReturn = combined.map((val) => {
      return { Name: val.description, Value: val.place_id };
    });

    const fullMLS = /^[a-zA-Z]\d{7}$/;

    if (fullMLS.test(searchString)) {
      toReturn.push({
        Name: `MLS Number ${searchString}`,
        Value: `MLS-${searchString}`
      });
    }

    toReturn = toReturn.filter((v, i, a) => a.findIndex((v2) => v2.Name === v.Name) === i);

    toReturn = toReturn.filter((value) => {
      return value.Name && value.Name.length > 0;
    });
    if (toReturn.length == 0) {
      toReturn.push({ Name: `${searchString} is not a recognized address or MLS number. Please enter the full 8 digit MLS number for MLS Search`, Value: '' });
    }
    return toReturn;
  }, [cityPlaces.placePredictions, addressPlaces.placePredictions]);

  const watcher = useWatch();

  const result = useMemo(() => {
    const results = getValidationResults(props.addressSelectedField, props.methods);
    return results;
  }, [watcher, props.methods.formState.isValid, props.methods.formState.isSubmitting]);

  function searchCitiesAndAddress(value) {
    if (value.length >= 3 || value.length == 0) {
      cityPlaces.getPlacePredictions({ input: value, types: ['(cities)'], componentRestrictions: { country: ['ca'] } });
      addressPlaces.getPlacePredictions({ input: value, componentRestrictions: { country: ['ca'] } });
    }
  }

  return (
    <FormControl id={'searchField'} variant={'square'} flexGrow={1} w={{ base: '100%', xl: '10%' }} isInvalid={result != undefined}>
      <CreatableSelect<PropertyListValueModel>
        variant='square'
        menuPortalTarget={document.body}
        components={components}
        useBasicStyles={true}
        chakraStyles={{
          singleValue: (provided) => ({
            ...provided,
            textAlign: 'left'
          }),
          menuList: (provided) => ({
            ...provided,
            marginTop: '-9px',
            borderRadius: 0
          }),
          placeholder: (provided) => ({
            ...provided,
            textAlign: 'left'
          })
        }}
        inputValue={searchString}
        isClearable
        noOptionsMessage={(val) => {
          return `${val.inputValue} is not a recognized address or MLS number. Please enter the full 8 digit MLS number for MLS Search`;
        }}
        isValidNewOption={() => false}
        onInputChange={(newValue) => {
          setHasSearched(true);
          setSearchString(newValue);
          searchCitiesAndAddress(newValue);
        }}
        onChange={(newVal) => {
          setValue(newVal);
          const matchingPlace = [...cityPlaces.placePredictions, ...addressPlaces.placePredictions].filter((val) => {
            return val.place_id == newVal.Value;
          });
          if (matchingPlace && matchingPlace.length > 0) {
            addressPlaces.placesService.getDetails({ placeId: matchingPlace[0].place_id }, (result) => {
              props.onSelectedOption(newVal, result);
            });
          } else {
            props.onSelectedOption(newVal, null);
          }
        }}
        blurInputOnSelect
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        placeholder={props.placeHolder}
        value={isFocus ? { Value: -1, Name: '' } : value}
        getOptionValue={(opt) => {
          return opt?.Value?.toString();
        }}
        menuIsOpen={hasSearched && combinedResults.length > 0 && searchString.length >= 3}
        getOptionLabel={(opt) => {
          return opt?.Name;
        }}
        isOptionDisabled={(opt) => {
          if (!opt.Value || opt.Value == '') {
            return true;
          }
          return false;
        }}
        options={combinedResults}
      />
      <HiddenField name={props.addressSelectedField} />
      <FormErrorMessage w={'100%'} paddingBottom={'5px'} marginTop={{ base: '0px' }}>
        {result?.message?.toString()}
      </FormErrorMessage>
    </FormControl>
  );
};

export const getValidationResults = <T,>(name: string, methods: UseFormReturn<T, any, T>) => {
  if (name.indexOf('.') === -1) {
    return methods.formState.errors[name];
  }

  const keys = name.split('.');
  const result = keys.reduce((obj, key) => (obj && obj[key] !== undefined ? obj[key] : undefined), methods.formState.errors as any);
  return result;
};
