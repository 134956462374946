import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useToast } from '@chakra-ui/react';
import { FormDefinitionForm } from '@cksoftware/react-base';
import { useFormDefinitionForm } from '@cksoftware/react-base';
import { submitEmailRequest } from '../api/submitEmailRequest';
import { EmailRequestFormModel } from '../models/EmailRequestForm';
import { EmailRequestFormDefinition } from '../models/EmailRequestFormDefinition';

export type EmailRequestProps = {
  isOpen: boolean;
  onClose: () => void;
  model: EmailRequestFormModel;
};
export const EmailRequestModal = ({ isOpen, onClose, model }: EmailRequestProps) => {
  const toast = useToast();

  const onSubmit = async () => {
    await submitEmailRequest(form.methods.getValues());
    toast({
      title: 'Email sent.',
      description: 'Your request has been received and a member of the team will be reaching out soon.',
      status: 'success'
    });
    onClose();
  };

  const form = useFormDefinitionForm(EmailRequestFormDefinition, null, model);
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'4xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader paddingBottom={'0'}>Contact VALT</ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingTop={'0'}>
          <FormDefinitionForm formDefinitionForm={form} onSubmit={onSubmit} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
