import { ProtectedRoute } from '@cksoftware/react-base';
import { useRoutes } from 'react-router-dom';
import { MainLayout } from './components/layout/mainLayout';
import { WideLayout } from './components/layout/wideLayout';
import { AppRoles } from './constants/constants';
import { UserManagement } from './features/admin/UserManagement';
import { ConfirmEmail } from './features/auth/ConfirmEmail';
import { ForgotPassword } from './features/auth/ForgotPassword';
import { Login } from './features/auth/Login';
import { ResetPassword } from './features/auth/ResetPassword';
import ClientOnboardingFunnel from './features/newClientFunnel/ClientOnboardingFunnel';
import { FunnelComplete } from './features/newClientFunnel/FunnelComplete';
import { HomeDetails } from './features/portal/HomeDetails/HomeDetails';
import { Portal } from './features/portal/Portal';

const protectedRoutes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <ClientOnboardingFunnel /> },
      { path: '/funnel-complete', element: <FunnelComplete /> },
      { path: '/login', element: <Login /> },
      { path: '/forgot-password', element: <ForgotPassword /> },
      { path: '/reset-password', element: <ResetPassword /> },
      { path: '/confirm-email', element: <ConfirmEmail /> },
      {
        path: '/portal/details',
        element: (
          <ProtectedRoute>
            <HomeDetails />
          </ProtectedRoute>
        )
      }
    ]
  },
  {
    path: '/portal',
    element: (
      <ProtectedRoute>
        <WideLayout />
      </ProtectedRoute>
    ),
    children: [{ path: '/portal', element: <Portal /> }]
  },
  {
    path: '/admin',
    element: (
      <ProtectedRoute allowedRoles={[AppRoles.UserAdmin]}>
        <MainLayout />
      </ProtectedRoute>
    ),
    children: [{ path: '/admin/users', element: <UserManagement /> }]
  }
];

export const AppRoutes = () => {
  const element = useRoutes([...protectedRoutes]);

  return <>{element}</>;
};
