import { useQuery } from '@tanstack/react-query';
import { getAxios } from '../../../util/axios';
import { HomeEvaluationRequest } from '../models/HomeEvaluationRequest';
import { HomeEvaluationResponse } from '../models/HomeEvaluationResponse';

const getHomeEvaluationResult = async (request: HomeEvaluationRequest): Promise<HomeEvaluationResponse> => {
  return (
    await getAxios().get(`/homeevaluation`, {
      params: request
    })
  ).data;
};

export const useHomeEvaluationQuery = (request: HomeEvaluationRequest) => {
  return useQuery({
    queryKey: ['homeEvaluation', request.addressLineOne, request.city, request.province],
    queryFn: () => getHomeEvaluationResult(request)
  });
};
