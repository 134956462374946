import { PropertySearchResponse } from '../models/PropertySearchResponse';

export const getHomeSizeSquareFootage = (details: PropertySearchResponse) => {
  if (details.AboveGradeFinishedArea) {
    return convertToSquareFeet(details.AboveGradeFinishedArea, details.AboveGradeFinishedAreaUnits);
  }
  if (details.LivingArea) {
    return convertToSquareFeet(details.LivingArea, details.LivingAreaUnits);
  }
  if (details.BuildingAreaTotal) {
    return convertToSquareFeet(details.BuildingAreaTotal, details.BuildingAreaUnits);
  }
  return 0;
};

const convertToSquareFeet = (area: number, units: string) => {
  if (units === 'square meters') {
    return Math.round(area * 10.764);
  }
  return Math.round(area);
};
