import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { FormDefinitionForm } from '@cksoftware/react-base';
import { useFormDefinitionForm } from '@cksoftware/react-base';
import { HomeListingFilterModel } from '../../models/HomeListingFilterModel';
import { HomeListingFilterDialogFormDefinition } from './HomeListingFilterDialogFormDefinition';

type HomeListingFitlerDialogProps = {
  filterModel: HomeListingFilterModel;
  onSearch: (model: HomeListingFilterModel) => void;
  showSearchButton: boolean;
  isOpen: boolean;
  onClose: () => void;
};

export const HomeListingFilterDialog = (props: HomeListingFitlerDialogProps) => {
  const form = useFormDefinitionForm(HomeListingFilterDialogFormDefinition, null, props.filterModel);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size={'4xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Home Search Filters</ModalHeader>
        <ModalBody paddingTop={0}>
          <FormDefinitionForm gap='5px' formDefinitionForm={form} onSubmit={props.onSearch} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
