import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { NumberSlider, formatCurrency } from '@cksoftware/react-base';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { LoadingSpinner } from '../../../../components/misc/LoadingSpinner';
import { useHomeEvaluationQuery } from '../../../homeEvaluation/api/useHonestDoorEvaluation';
import { NewClientForm } from '../../models/NewContactForm';

export const HomeValueStep = () => {
  const methods = useFormContext<NewClientForm>();
  const address = methods.getValues('Client.ClientHomeEvaluation.Address');
  const evaluationQuery = useHomeEvaluationQuery({
    addressLineOne: address.AddressLineOne,
    city: address.City,
    province: address.Province
  });
  useWatch({
    name: 'Client.ClientHomeEvaluation.CustomerValue'
  });
  useEffect(() => {
    const existingValue = methods.getValues('Client.ClientHomeEvaluation.CustomerValue');
    if (!existingValue || isNaN(existingValue)) {
      if (evaluationQuery.data && evaluationQuery.data.Found) {
        methods.setValue('Client.ClientHomeEvaluation.HonestDoorValue', evaluationQuery.data.Value);
        methods.setValue('Client.ClientHomeEvaluation.CustomerValue', evaluationQuery.data.Value);
      } else if (evaluationQuery.data && !evaluationQuery.data.Found) {
        methods.setValue('Client.ClientHomeEvaluation.CustomerValue', 400000);
      }
    }
  }, [evaluationQuery.data]);

  return evaluationQuery.isLoading || !evaluationQuery.data ? (
    <LoadingSpinner />
  ) : (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Box w={'100%'} textAlign={'center'}>
        <Heading>Let's find out the value of your home</Heading>

        <Flex w={'80%'} margin={'auto'} backgroundColor={'#F5F5F5'} fontSize={'xl'} alignItems={'center'} flexDir={'column'} gap={'20px'} padding={'10px 0px 40px 0px'}>
          <Text>
            {address.AddressLineOne} {address.City}, {address.Province}
          </Text>
          {evaluationQuery.data.Found && (
            <>
              <Box>
                <Text>Our AI tool estimates the value of your home.</Text>
                <Text>Here's what we've come up with:</Text>
              </Box>
              <Heading variant={'green'}>{formatCurrency(evaluationQuery.data.Value)}</Heading>
            </>
          )}
          {!evaluationQuery.data.Found && (
            <>
              <Box>
                <Text>We're sorry, our evaluation tool was not able to find your property.</Text>
              </Box>
            </>
          )}
          <Text>What do you think your home is worth?</Text>
          <NumberSlider
            name={'Client.ClientHomeEvaluation.CustomerValue'}
            config={{
              min: evaluationQuery.data && evaluationQuery.data.Found ? evaluationQuery.data.Value - 100000 : 0,
              max: evaluationQuery.data && evaluationQuery.data.Found ? evaluationQuery.data.Value + 200000 : 2000000,
              step: evaluationQuery.data && evaluationQuery.data.Found ? 1000 : 10000,
              startingValue: evaluationQuery.data && evaluationQuery.data.Found ? evaluationQuery.data.Value : 400000
            }}
          />
          <Heading variant={'green'}>{formatCurrency(methods.getValues('Client.ClientHomeEvaluation.CustomerValue'))}</Heading>
        </Flex>
      </Box>
    </motion.div>
  );
};
