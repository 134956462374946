import { Box, FormControl, FormLabel, Switch, Text } from '@chakra-ui/react';
import { useState } from 'react';

type HomeListingPriceSwitchProps = {
  onChange: (isChecked: boolean) => void;
};
export const HomeListingPriceSwitch = (props: HomeListingPriceSwitchProps) => {
  const [isSwitched, setIsSwitched] = useState<boolean>(false);

  return (
    <Box w={'100%'} textAlign={'right'}>
      <FormControl display='flex' alignItems='center' justifyItems={'flex-end'} w={'100%'}>
        <Switch
          id='email-alerts'
          isChecked={isSwitched}
          onChange={(val) => {
            setIsSwitched(val.target.checked);
            props.onChange(val.target.checked);
          }}
        />
        <FormLabel htmlFor='email-alerts' ml={'4px'} mb='0'>
          {isSwitched && <Text>Show Swap Price</Text>}
          {!isSwitched && <Text>Show Listing Price</Text>}
        </FormLabel>
      </FormControl>
    </Box>
  );
};
