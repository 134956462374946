import { Box, Flex, HStack, Icon, Text } from '@chakra-ui/react';
import { formatCurrency } from '@cksoftware/react-base';
import { IconBath, IconBed, IconRuler } from '@tabler/icons-react';
import { HomeDetailsResponseModel } from '../models/HomeDetailsResponseModel';
import { getHomeSizeSquareFootage } from '../util/getHomeSizeSquareFootage';

type HomeBasicInfoProps = {
  homeDetailsModel: HomeDetailsResponseModel;
};
export const HomeBasicInfo = ({ homeDetailsModel }: HomeBasicInfoProps) => {
  return (
    <Flex gap={0} flexDir={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
      <Box>
        <Text fontSize={'xx-large'} color={'gold.100'}>
          <Text>
            {homeDetailsModel.TradeInCost > 0 ? 'Pay' : 'Save'} {formatCurrency(Math.abs(homeDetailsModel.TradeInCost))}
          </Text>
        </Text>
        <Text>{homeDetailsModel.PropertyListingResponse.UnparsedAddress}</Text>
        <Text>
          {homeDetailsModel.PropertyListingResponse.City}, {homeDetailsModel.PropertyListingResponse.StateOrProvince} {homeDetailsModel.PropertyListingResponse.PostalCode}
        </Text>
        <Text>List Price: {formatCurrency(homeDetailsModel.PropertyListingResponse.ListPrice)}</Text>
        <Text>MLS Number: {homeDetailsModel.PropertyListingResponse.ListingId}</Text>
        <HStack>
          <Icon as={IconBath} /> <Text>{homeDetailsModel.PropertyListingResponse.BathroomsTotalInteger}</Text>
          <Icon as={IconBed} /> <Text>{homeDetailsModel.PropertyListingResponse.BedroomsTotal}</Text>
          <Icon as={IconRuler} /> <Text>{getHomeSizeSquareFootage(homeDetailsModel.PropertyListingResponse)} sqft</Text>
        </HStack>
      </Box>
    </Flex>
  );
};
