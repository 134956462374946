export enum PropertyDisplayType {
  None = 'None',
  Hidden = 'Hidden',
  AddressSelector = 'AddressSelector',
  IconRadio = 'IconRadio',
  IconCheckbox = 'IconCheckbox',
  IconQuantity = 'IconQuantity',
  DatePicker = 'DatePicker',
  CurrencyInput = 'CurrencyInput',
  SelectRadio = 'SelectRadio',
  Select = 'Select',
  TextArea = 'TextArea',
  BooleanRadio = 'BooleanRadio',
  MultiSelect = 'MultiSelect'
}
