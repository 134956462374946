import { useToast } from '@chakra-ui/react';
import { FormDefinitionForm, RequestResetPasswordFormDefinition, useFormDefinitionForm } from '@cksoftware/react-base';
import { useNavigate } from 'react-router-dom';
import { useValtAuth } from '../../util/useValtAuth';

export const ForgotPassword = () => {
  const form = useFormDefinitionForm(RequestResetPasswordFormDefinition);
  const auth = useValtAuth();
  const toast = useToast();
  const nav = useNavigate();

  return (
    <div>
      <FormDefinitionForm
        formDefinitionForm={form}
        onSubmit={async (model) => {
          await auth.requestResetPassword(model.Email);
          toast({
            title: 'Request Received',
            description: 'Thanks. Password reset instructions have been sent to any account matching the entered email. ',
            status: 'success',
            duration: 9000,
            isClosable: true
          });
          nav('/login');
        }}
      />
    </div>
  );
};
