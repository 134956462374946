export const calculateDistanceOfBounds = (latTopRight: number, lonTopRight: number, latBottomLeft: number, lonBottomLeft: number) => {
  const earthRadiusKm = 6371; // Radius of the Earth in kilometers
  const dLat = toRadians(latBottomLeft - latTopRight); // Difference in latitude
  const dLon = toRadians(lonTopRight - lonBottomLeft); // Difference in longitude
  const radLatTopRight = toRadians(latTopRight);
  const radLatBottomLeft = toRadians(latBottomLeft);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(radLatTopRight) * Math.cos(radLatBottomLeft);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusKm * c; // Diagonal distance in kilometers
};

function toRadians(value: number): number {
  return (value * Math.PI) / 180;
}
