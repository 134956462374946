import { Box, Flex, Progress, useDisclosure } from '@chakra-ui/react';
import { useHoverState } from '@cksoftware/react-base';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapBoundingBox } from '../../../globalModels/common/mapBoundingBox';
import { MapLocation } from '../../../globalModels/common/mapLocation';
import { calculateDistanceOfBounds } from '../../../util/calculateDistanceOfBounds';
import { useHomeListingQuery } from '../api/useHomeListingQuery';
import { HomeListingFilterModel } from '../models/HomeListingFilterModel';
import { HomeListingResponseModel } from '../models/HomeListingResponseModel';
import { HomeListingFilterForm } from './HomeListingFilters/HomeListingFilterForm';
import { HomeListingPriceSwitch } from './HomeListingFilters/HomeListingPriceSwitch';
import { HomeListingItem } from './HomeListingItem/HomeListingItem';
import { HomeListingMap } from './HomeListingMap/HomeListingMap';

type HomeListingContainerProps = {
  filterModel: HomeListingFilterModel;
  setFilterModel: (model: HomeListingFilterModel) => void;
  queryKey: string;
};

export const HomeListingContainer = ({ filterModel, setFilterModel, queryKey }: HomeListingContainerProps) => {
  const homeListings = useHomeListingQuery(filterModel, queryKey);
  const nav = useNavigate();
  const [hoverItem, onHoverEnd, onHoverStart] = useHoverState<HomeListingResponseModel>(undefined);
  const [mapMarkerHoverItem, , onMapMarkerHoverStart] = useHoverState<HomeListingResponseModel>(undefined);
  const [mapBounds, setMapBounds] = useState<MapBoundingBox>();
  const filterDisclosure = useDisclosure();

  useEffect(() => {
    if (filterModel.MlsNumber && homeListings.data && homeListings.data.length == 1) {
      nav('/portal/details?propertyId=' + homeListings.data[0].PropertyListingResponse.ListingKey);
    }
  }, [filterModel.MlsNumber, homeListings.data]);

  const mapCenter = useMemo(() => {
    if (filterModel.Lat && filterModel.Lng) {
      return { lat: filterModel.Lat, lng: filterModel.Lng };
    }
    if (homeListings.data && homeListings.data.length > 0) {
      return { lat: homeListings.data[0].PropertyListingResponse.Latitude, lng: homeListings.data[0].PropertyListingResponse.Longitude };
    }
    return { lat: 51.04175624622379, lng: -114.06586818223111 };
  }, [homeListings.data, filterModel.Lat, filterModel.Lng]);

  const onMapMove = useCallback(
    (boundingBox: MapBoundingBox, mapCenter: MapLocation) => {
      const distance = calculateDistanceOfBounds(boundingBox.TopRight.Lat, boundingBox.TopRight.Lng, boundingBox.BottomLeft.Lat, boundingBox.BottomLeft.Lng);
      setMapBounds(boundingBox);
      const newFilter = { ...filterModel };
      newFilter.RangeKm = Math.round(distance);
      newFilter.Lat = mapCenter.Lat;
      newFilter.Lng = mapCenter.Lng;
      setFilterModel(newFilter);
    },
    [filterModel, setFilterModel, mapBounds, homeListings.refetch]
  );

  const scrollRef = useRef<HTMLDivElement>();

  const filteredHomeData = useMemo(() => {
    if (!homeListings.data) {
      return [];
    }
    return homeListings.data.filter((value) => {
      if (!mapBounds) {
        return true;
      }
      return (
        value.PropertyListingResponse.Latitude <= mapBounds.TopRight.Lat &&
        value.PropertyListingResponse.Latitude >= mapBounds.BottomLeft.Lat &&
        value.PropertyListingResponse.Longitude <= mapBounds.TopRight.Lng &&
        value.PropertyListingResponse.Longitude >= mapBounds.BottomLeft.Lng
      );
    });
  }, [homeListings.data, mapBounds]);

  return (
    <Box overflow={'hidden'} h={{ base: 'calc(100vh - 180px)', sm: 'calc(100vh - 120px)' }}>
      <Box w={'100%'} margin={'auto'}>
        <HomeListingFilterForm
          filterModel={filterModel}
          onSearch={(model) => {
            setFilterModel(model);
          }}
          showSearchButton={false}
          showFilterButton={true}
          onClose={filterDisclosure.onClose}
          onOpen={filterDisclosure.onOpen}
          isOpen={filterDisclosure.isOpen}
        />
      </Box>
      <Flex gap={'0'} h={{ base: 'calc(100vh - 120px)', sm: 'calc(100vh - 165px)' }}>
        <Box w={{ base: '100%', xl: '70%', '2xl': '50%' }}>
          {homeListings.isFetching && <Progress size='xs' isIndeterminate />}
          <Flex flexDir={'column'} h={'100%'} gap={'10px'} overflowY={'auto'}>
            <HomeListingPriceSwitch
              onChange={(checked) => {
                const currentModel = { ...filterModel };
                currentModel.ShowPrice = checked;
                setFilterModel(currentModel);
              }}
            />
            {filteredHomeData.map((listing, index) => {
              return (
                <div ref={scrollRef} key={index}>
                  <HomeListingItem
                    isShowingFullPrice={filterModel.ShowPrice}
                    onHoverStart={onHoverStart}
                    onHoverEnd={onHoverEnd}
                    isHoverItem={mapMarkerHoverItem == listing}
                    key={index}
                    model={listing}
                  />
                </div>
              );
            })}
            {filteredHomeData.length == 0 && !homeListings.isFetching && <Box>Sorry, no results found. Try modifying your search parameters or moving the map.</Box>}
          </Flex>
        </Box>
        <Box h={'100%'} w={'100%'}>
          <HomeListingMap data={homeListings.data ?? []} hoverItem={hoverItem} onHoverMapMarker={onMapMarkerHoverStart} onMapMove={onMapMove} center={mapCenter} />
        </Box>
      </Flex>
    </Box>
  );
};
