import { PropertyListValueModel, formatCurrency } from '@cksoftware/react-base';

export const generatePriceDropdown = (min: number, max: number, step: number) => {
  const result: PropertyListValueModel[] = [];
  for (let i = min; i <= max; i += step) {
    result.push({
      Value: i,
      Name: formatCurrency(i)
    });
  }
  return result;
};
