import { AddressSelectorConfig, WizardDefinition } from '@cksoftware/react-base';
import { EmploymentStatus } from '../../enums/contact/employmentStatus';
import { IncomeLength } from '../../enums/contact/incomeLength';
import { IncomeType } from '../../enums/contact/incomeType';
import { PropertyDisplayType } from '../../enums/form/PropertyDisplayType';
import { NewClientForm } from './models/NewContactForm';
import { HomeValueStep } from './steps/custom/HomeValueStep';

const mainAddressConfig: AddressSelectorConfig<NewClientForm> = {
  cityNameField: 'Client.ClientHomeEvaluation.Address.City',
  provinceField: 'Client.ClientHomeEvaluation.Address.Province',
  addressField: 'Client.ClientHomeEvaluation.Address.AddressLineOne',
  postalCodeField: 'Client.ClientHomeEvaluation.Address.PostalCode',
  addressSelectedField: 'Client.ClientHomeEvaluation.Address.HasSelectedAddress',
  latField: 'Client.ClientHomeEvaluation.Address.Lat',
  lngField: 'Client.ClientHomeEvaluation.Address.Lng',
  searchStringField: 'Client.ClientHomeEvaluation.Address.AddressSearchString'
};

const previousAddressConfig: AddressSelectorConfig<NewClientForm> = {
  cityNameField: 'Client.ClientHomeEvaluation.FormerAddress.City',
  provinceField: 'Client.ClientHomeEvaluation.FormerAddress.Province',
  addressField: 'Client.ClientHomeEvaluation.FormerAddress.AddressLineOne',
  postalCodeField: 'Client.ClientHomeEvaluation.FormerAddress.PostalCode',
  addressSelectedField: 'Client.ClientHomeEvaluation.FormerAddress.HasSelectedAddress',
  latField: 'Client.ClientHomeEvaluation.FormerAddress.Lat',
  lngField: 'Client.ClientHomeEvaluation.FormerAddress.Lng',
  searchStringField: 'Client.ClientHomeEvaluation.FormerAddress.AddressSearchString'
};

export const NewContactWizardDefinition: WizardDefinition<NewClientForm> = {
  progressBar: {
    isStepper: false
  },
  steps: [
    {
      header: 'Tell us about your home',
      stepName: 'Owns Home',
      progressStepIndex: 0,
      questions: [{ key: 'Client.OwnsHome', label: 'Do you own a home?', propertyDisplayType: PropertyDisplayType.BooleanRadio }]
    },
    {
      header: 'Tell us about your home',
      stepName: 'Address',
      progressStepIndex: 1,
      questions: [
        {
          key: 'Client.ClientHomeEvaluation.Address.AddressSearchString',
          label: 'What is your address?',
          propertyDisplayType: PropertyDisplayType.AddressSelector,
          propertyDisplayTypeConfig: mainAddressConfig
        },
        {
          key: 'Client.ClientHomeEvaluation.Address.HasSelectedAddress',
          propertyDisplayType: PropertyDisplayType.Hidden
        },
        {
          label: 'How many years have you lived here?',
          key: 'Client.ClientHomeEvaluation.YearsAtAddress'
        },
        {
          key: 'Client.ClientHomeEvaluation.FormerAddress.AddressSearchString',
          label: 'Where did you live before?',
          propertyDisplayType: PropertyDisplayType.AddressSelector,
          propertyDisplayTypeConfig: previousAddressConfig,
          hideIf: {
            property: 'Client.ClientHomeEvaluation.YearsAtAddress',
            evalFunc: (formAccessor) => {
              const yearsAtAddress = formAccessor.getValues('Client.ClientHomeEvaluation.YearsAtAddress') as any;
              if (yearsAtAddress == '' || yearsAtAddress === undefined || isNaN(yearsAtAddress) || Number(yearsAtAddress) > 2) {
                return true;
              }
              return false;
            }
          }
        },
        {
          key: 'Client.ClientHomeEvaluation.Address.Lat',
          propertyDisplayType: PropertyDisplayType.Hidden
        },
        {
          key: 'Client.ClientHomeEvaluation.Address.Lng',
          propertyDisplayType: PropertyDisplayType.Hidden
        }
      ],
      skipIf: {
        property: 'Client.OwnsHome',
        value: [false]
      }
    },
    {
      stepName: 'Home Value',
      progressStepIndex: 2,
      customRenderer: HomeValueStep,
      questions: [],
      skipIf: {
        property: 'Client.OwnsHome',
        value: [false]
      }
    },
    {
      header: 'Tell us about your home',
      stepName: 'Mortgage',
      progressStepIndex: 3,
      questions: [
        {
          key: 'Client.ClientHomeEvaluation.HasMortgage',
          label: 'Do you have a mortgage?',
          propertyDisplayType: PropertyDisplayType.BooleanRadio
        },
        {
          key: 'Client.ClientHomeEvaluation.MortgageOwed',
          label: 'Approximately, how much do you owe on your mortgage?',
          propertyDisplayType: PropertyDisplayType.CurrencyInput,
          underText: "Don't worry about exact values, we will get more specific later.",
          hideIf: {
            property: 'Client.ClientHomeEvaluation.HasMortgage',
            value: [false]
          }
        }
      ],
      skipIf: {
        property: 'Client.OwnsHome',
        value: [false]
      }
    },

    {
      header: 'Tell us about your employment',
      stepName: 'Employment Status',
      progressStepIndex: 4,
      questions: [
        {
          key: 'Client.ClientEmployment.IncomeType',
          label: 'I know my:',
          propertyDisplayType: PropertyDisplayType.SelectRadio
        }
      ]
    },
    {
      header: 'Tell us about your employment',
      stepName: 'Employment Status',
      progressStepIndex: 4,
      questions: [
        {
          key: 'Client.ClientEmployment.Income',
          label: 'My pre-tax {{Client.ClientEmployment.IncomeTypeString}} is:',
          propertyDisplayType: PropertyDisplayType.CurrencyInput
        }
      ]
    },
    {
      header: 'Tell us about your employment',
      stepName: 'Employment Status',
      progressStepIndex: 4,
      questions: [
        {
          key: 'Client.ClientEmployment.IncomeLength',
          label: 'How long have you been receiving this income?',
          propertyDisplayType: PropertyDisplayType.SelectRadio
        }
      ]
    },
    {
      header: 'Tell us about yourself',
      stepName: 'Personal Info',
      progressStepIndex: 5,
      questions: [
        { key: 'Client.FirstName', label: 'First Name' },
        { key: 'Client.LastName', label: 'Last Name' },
        { key: 'Client.Email', label: 'Email' },
        { key: 'Client.Phone', label: 'Phone Number', mask: '(999) 999-9999' }
      ]
    }
  ],
  defaultData: {
    Client: {
      Email: 'testymctest@example.ca',
      FirstName: 'Testy',
      LastName: 'McTesterson',
      Id: 0,
      Phone: '(780) 555-5555',
      OwnsHome: true,
      ClientHomeEvaluation: {
        Address: {
          AddressLineOne: '40 Kentish Dr SW',
          City: 'Calgary',
          Province: 'Alberta',
          PostalCode: 'T2V 2L3',
          AddressSearchString: '40 Kentish Dr SW Calgary AB',
          HasSelectedAddress: true,
          Lat: 50.987668358465335,
          Lng: -114.07542774443866
        },
        YearsAtAddress: 1,
        FormerAddress: {
          AddressLineOne: '40 Kentish Dr SW',
          City: 'Calgary',
          Province: 'Alberta',
          PostalCode: 'T2V 2L3',
          AddressSearchString: '40 Kentish Dr SW Calgary AB',
          HasSelectedAddress: true,
          Lat: 50.987668358465335,
          Lng: -114.07542774443866
        },
        CustomerValue: undefined,
        HasMortgage: true,
        MortgageOwed: 400000,
        HonestDoorValue: undefined
      },
      ClientEmployment: {
        Income: 100000,
        IncomeType: IncomeType.YearlySalary,
        IncomeLength: IncomeLength.TwoToThreeYears,
        EmploymentStatus: EmploymentStatus.SelfEmployed
      }
    }
  }
};
