import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { PropertyResponseMedia } from '../models/PropertySearchResponse';

type HomeDetailsMediaDisplayProps = {
  media: PropertyResponseMedia[];
};

export const HomeDetailsMediaDisplay = (props: HomeDetailsMediaDisplayProps) => {
  const [images, setImages] = useState([]);
  useEffect(() => {
    setImages(
      props.media
        .filter((value) => value.MediaCategory == 'Property Photo')
        .map((value) => {
          return {
            original: value.MediaURL,
            thumbnail: value.MediaURL
          };
        })
    );
  }, props.media);
  return (
    <Box>
      <ImageGallery items={images} useBrowserFullscreen={false} showThumbnails={false} autoPlay={true} lazyLoad={true} />
    </Box>
  );
};
