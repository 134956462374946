import { Box } from '@chakra-ui/react';
import { GoogleMap, InfoWindow, Libraries, Marker, MarkerClusterer, useLoadScript } from '@react-google-maps/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import { MapBoundingBox } from '../../../../globalModels/common/mapBoundingBox';
import { MapLocation } from '../../../../globalModels/common/mapLocation';
import { HomeListingResponseModel } from '../../models/HomeListingResponseModel';
import { HomeListingMapMarkerInfo } from './HomeListingMapMarkerInfo';

type HomeListingMapProps = {
  data: HomeListingResponseModel[];
  hoverItem: HomeListingResponseModel;
  onHoverMapMarker: (property: HomeListingResponseModel) => void;
  onMapMove: (boundingBox: MapBoundingBox, center: MapLocation) => void;
  center: { lat: number; lng: number };
  zoom?: number;
};

const libraries: Libraries = ['places'];

export const HomeListingMap = (props: HomeListingMapProps) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBg0zU9P7a4jxr3K9SCEvxQCy7EOPiDWAM',
    libraries: libraries
  });
  const mapRef = useRef<GoogleMap>();
  const [map, setMap] = useState<google.maps.Map>();
  const [selectedCenter, setSelectedCenter] = useState<HomeListingResponseModel>(null);

  const options = {
    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  };

  useEffect(() => {
    if (mapRef?.current) {
      //TODO: this crashes the map?
      //mapRef.current.panTo({ lat: props.center.lat, lng: props.center.lng });
    }
  }, [props.center.lat, props.center.lng]);

  const boundsCallback = useCallback(() => {
    if (!map) {
      return;
    }
    const topRight = map.getBounds().getNorthEast();
    const bottomLeft = map.getBounds().getSouthWest();
    const center = map.getCenter();
    props.onMapMove(
      {
        TopRight: { Lat: topRight.lat(), Lng: topRight.lng() },
        BottomLeft: { Lat: bottomLeft.lat(), Lng: bottomLeft.lng() }
      },
      { Lat: center.lat(), Lng: center.lng() }
    );
  }, [map, props.onMapMove]);

  if (!isLoaded) {
    return null;
  }

  const createKey = (lat, lng, index) => {
    return lat + lng + index;
  };

  return (
    <Box h={'100%'} w={'100%'}>
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{ height: '100%', width: '100%' }}
        mapContainerClassName='map-container'
        onLoad={(map) => {
          setMap(map);
          map.setCenter({ lat: props.center.lat, lng: props.center.lng });
        }}
        onResize={() => {}}
        onZoomChanged={boundsCallback}
        onDragEnd={boundsCallback}
        options={{
          fullscreenControl: false,
          mapTypeControl: true,
          controlSize: 30,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DEFAULT,
            position: google.maps.ControlPosition.TOP_LEFT,
            mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.HYBRID]
          }
        }}
        zoom={props.zoom ?? 13}>
        <MarkerClusterer options={options}>
          {(clusterer) => {
            return (
              <>
                {props.data.map((location, index) => (
                  <Marker
                    key={createKey(location.PropertyListingResponse.Latitude, location.PropertyListingResponse.Longitude, index)}
                    position={{ lat: location.PropertyListingResponse.Latitude, lng: location.PropertyListingResponse.Longitude }}
                    clusterer={clusterer}
                    animation={props.hoverItem == location ? google.maps.Animation.BOUNCE : null}
                    onClick={() => {
                      setSelectedCenter(location);
                    }}>
                    {selectedCenter && selectedCenter == location && (
                      <InfoWindow
                        onCloseClick={() => {
                          setSelectedCenter(null);
                        }}
                        position={{
                          lat: selectedCenter.PropertyListingResponse.Latitude,
                          lng: selectedCenter.PropertyListingResponse.Longitude
                        }}>
                        <HomeListingMapMarkerInfo property={location} />
                      </InfoWindow>
                    )}
                  </Marker>
                ))}
              </>
            );
          }}
        </MarkerClusterer>
      </GoogleMap>
    </Box>
  );
};
