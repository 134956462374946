import { Box, Stack, Text } from '@chakra-ui/react';
import { formatCurrency } from '@cksoftware/react-base';

type HomeListingTradePriceDisplayProps = {
  price: number;
  isTradeInPrice: boolean;
};

export const HomeListingTradePriceDisplay = (props: HomeListingTradePriceDisplayProps) => {
  return (
    <Box h={'100%'} bgColor={'gold.100'}>
      <Stack direction={{ base: 'row', xl: 'column' }} alignItems={'center'} alignContent={'center'} justifyContent={'center'} gap={'0'}>
        {props.isTradeInPrice && <Text>{props.price > 0 ? 'Pay' : 'Save'}</Text>}
        <Text fontSize={{ base: 'lg', xl: 'xx-large' }} fontWeight={'medium'}>
          {' '}
          &nbsp;{formatCurrency(props.price)}
        </Text>
      </Stack>
    </Box>
  );
};
