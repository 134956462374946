import { Box, Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { formatCurrency } from '@cksoftware/react-base';
import { IconBath, IconBed, IconRuler } from '@tabler/icons-react';
import { HomeListingResponseModel } from '../../models/HomeListingResponseModel';
import { HomeListingPhoto } from './HomeListingPhoto';
import { HomeListingTradePriceDisplay } from './HomeListingTradePriceDisplay';
type HomeListingItemProps = {
  model: HomeListingResponseModel;
  onHoverStart: (item: HomeListingResponseModel) => void;
  onHoverEnd: (item: HomeListingResponseModel) => void;
  isHoverItem: boolean;
  isShowingFullPrice: boolean;
};

export const HomeListingItem = (props: HomeListingItemProps) => {
  return (
    <Box
      borderWidth={props.isHoverItem ? '2px' : '1px'}
      borderColor={props.isHoverItem ? 'green.100' : 'gold.100'}
      cursor={'pointer'}
      onClick={() => {
        window.open(`/portal/details?propertyId=${props.model.PropertyListingResponse.ListingKey}`, '_blank');
      }}
      onMouseEnter={() => {
        props.onHoverStart(props.model);
      }}
      onMouseLeave={() => {
        props.onHoverEnd(props.model);
      }}>
      <Flex gap={2} flexWrap={{ base: 'wrap', xl: 'unset' }}>
        <Box minW={{ base: '120px', xl: '155px' }} w={{ base: '120px', xl: '155px' }}>
          <HomeListingPhoto src={props.model.PropertyListingResponse.Media[0].MediaURL} />
        </Box>
        <Box>
          <VStack alignItems={'flex-start'} gap={0}>
            <Box>
              <VStack gap={0} alignItems={'flex-start'}>
                <Text fontSize={'md'} noOfLines={1}>
                  {props.model.PropertyListingResponse.UnparsedAddress}
                </Text>
                <Text fontSize={'xs'}>
                  {props.model.PropertyListingResponse.City}, {props.model.PropertyListingResponse.StateOrProvince}
                </Text>
                <Text fontSize={'xs'}>{formatCurrency(props.model.PropertyListingResponse.ListPrice)}</Text>
              </VStack>
            </Box>
            <Box>
              <HStack>
                <Icon as={IconBath} /> <Text>{props.model.PropertyListingResponse.BathroomsTotalInteger}</Text>
                <Icon as={IconBed} /> <Text>{props.model.PropertyListingResponse.BedroomsTotal}</Text>
                <Icon as={IconRuler} /> <Text>{Math.round(props.model.PropertyListingResponse.AboveGradeFinishedArea)}</Text>
              </HStack>
            </Box>
          </VStack>
        </Box>
        <Box minW={'30%'} w={{ base: '100%', lg: '30%' }} marginLeft={'auto'}>
          <HomeListingTradePriceDisplay isTradeInPrice={props.model.IsTradeInPrice} price={props.model.Cost} />
        </Box>
      </Flex>
    </Box>
  );
};
