import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useIsMobile } from '../../util/useIsMobile.tsx';
import { HomeListingContainer } from './HomeListings/HomeListingContainer.tsx';
import { MobileHomeListingContainer } from './HomeListings/MobileHomeListingContainer.tsx';
import { Landing } from './Landing/Landing.tsx';
import { HomeListingFilterViewMode } from './models/FilterViewMode.ts';
import { DefaultHomeListingFilterModel, HomeListingFilterModel } from './models/HomeListingFilterModel.ts';

export const Portal = () => {
  const [hasSearched, setHasSearched] = useState(false);
  const queryKey = 'homeListings';
  const queryClient = useQueryClient();
  const isMobile = useIsMobile();
  const debouncedSetFilterModel = useDebouncedCallback((value) => {
    setFilterModel(value);
  }, 600);

  const [filterModel, setFilterModel] = useState<HomeListingFilterModel>({
    ...DefaultHomeListingFilterModel,
    ViewMode: HomeListingFilterViewMode.Landing
  });

  const onSearch = (newModel: HomeListingFilterModel) => {
    setFilterModel({
      ...newModel,
      ViewMode: HomeListingFilterViewMode.Normal
    });
    setHasSearched(true);
    queryClient.invalidateQueries({ queryKey: [queryKey] });
  };
  return (
    <>
      {!hasSearched ? (
        <Landing filterModel={filterModel} onSearch={onSearch} />
      ) : isMobile ? (
        <MobileHomeListingContainer
          filterModel={filterModel}
          setFilterModel={(model) => {
            debouncedSetFilterModel(model);
          }}
          queryKey={queryKey}
        />
      ) : (
        <HomeListingContainer
          filterModel={filterModel}
          setFilterModel={(model) => {
            debouncedSetFilterModel(model);
          }}
          queryKey={queryKey}
        />
      )}
    </>
  );
};
