import { Box, Heading, useDisclosure } from '@chakra-ui/react';
import { ChakraDialog, ChakraTable, useUserListQuery } from '@cksoftware/react-base';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { LoadingSpinner } from '../../components/misc/LoadingSpinner';
import { ValtUser } from '../../globalModels/user/valtUser';
import { EditUserForm } from './EditUserForm';
export const UserManagement = () => {
  const userListQuery = useUserListQuery<ValtUser>();
  const userEditDisclosure = useDisclosure();
  const columnHelper = createColumnHelper<ValtUser>();
  const [editingUser, setEditingUser] = useState<ValtUser>();
  const columns: ColumnDef<ValtUser>[] = [
    columnHelper.accessor('Email', { header: 'Email', enableGlobalFilter: true }),
    columnHelper.accessor('FirstName', { header: 'First Name', enableGlobalFilter: true }),
    columnHelper.accessor('LastName', { header: 'Last Name', enableGlobalFilter: true }),
    columnHelper.accessor('Roles', {
      header: 'Roles',
      enableGlobalFilter: true
    })
  ];

  if (userListQuery.isLoading || !userListQuery.data) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      <ChakraDialog onOpen={userEditDisclosure.onOpen} isOpen={userEditDisclosure.isOpen} onClose={userEditDisclosure.onClose} size='4xl'>
        <EditUserForm
          userModel={editingUser}
          onSaveFail={() => {
            userEditDisclosure.onClose();
          }}
          onSaveSuccess={() => {
            userEditDisclosure.onClose();
            userListQuery.refetch();
          }}
        />
      </ChakraDialog>
      <Heading>User Management</Heading>
      <ChakraTable
        data={userListQuery.data}
        columns={columns}
        onRowClick={(data) => {
          setEditingUser(data);
          userEditDisclosure.onOpen();
        }}
      />
    </Box>
  );
};
