import { useQuery } from '@tanstack/react-query';
import { HomeDetailsResponseModel } from '../models/HomeDetailsResponseModel';
import { getAxios } from '../../../util/axios';

const getHomeDetails = async (key: string): Promise<HomeDetailsResponseModel> => {
  return (await getAxios().get(`/portal/homedetails`, { params: { listingKey: key } })).data;
};

export const useHomeDetailsQuery = (listingKey: string) => {
  return useQuery({
    queryKey: ['homeDetails', listingKey],
    queryFn: () => getHomeDetails(listingKey),
    enabled: !!listingKey,
    retry: false
  });
};
