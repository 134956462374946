import { FormDefinition } from '@cksoftware/react-base';
import { PropertyDisplayType } from '../../../../enums/form/PropertyDisplayType';
import { SpecificOrMinFilterAmount } from '../../../../enums/form/SpecificOrMinFilterAmount.tsx';
import { generatePriceDropdown } from '../../../../util/generatePriceDropdown.ts';
import { HomeListingFilterViewMode } from '../../models/FilterViewMode.ts';
import { HomeListingFilterModel } from '../../models/HomeListingFilterModel';

export const HomeListingFilterFormDefinition: FormDefinition<HomeListingFilterModel> = {
  questions: [
    {
      key: 'HasSelectedAddress',
      propertyDisplayType: PropertyDisplayType.Hidden
    },
    {
      key: 'MinPrice',
      placeHolder: 'Min $',
      variant: 'square',
      propertyDisplayType: PropertyDisplayType.Select,
      propertyDisplayTypeConfig: {
        items: generatePriceDropdown(0, 5000000, 50000),
        isNumeric: true
      },
      controlStyleProps: {
        maxW: { base: 'unset', lg: '150px' },
        w: { base: '50%', xl: 'unset' },
        flexGrow: 1
      }
    },
    {
      key: 'MaxPrice',
      placeHolder: 'Max $',
      variant: 'square',
      propertyDisplayType: PropertyDisplayType.Select,
      propertyDisplayTypeConfig: {
        items: generatePriceDropdown(50000, 5000000, 50000),
        isNumeric: true
      },
      controlStyleProps: {
        maxW: { base: 'unset', lg: '150px' },
        w: { base: '50%', lg: 'unset' },
        flexGrow: 1
      }
    },
    {
      key: 'SquareFootageMin',
      variant: 'square',
      placeHolder: 'Min Sqft',
      hideIf: {
        property: 'ViewMode',
        value: [HomeListingFilterViewMode.Landing.valueOf()]
      },
      controlStyleProps: {
        maxW: '120px'
      }
    },
    {
      key: 'SquareFootageMax',
      variant: 'square',
      placeHolder: 'Max Sqft',
      hideIf: {
        property: 'ViewMode',
        value: [HomeListingFilterViewMode.Landing.valueOf()]
      },
      controlStyleProps: {
        maxW: '120px'
      }
    },
    {
      key: 'Bedrooms',
      variant: 'square',
      placeHolder: 'Beds',
      selectPlaceHolderValue: SpecificOrMinFilterAmount.Any,
      controlStyleProps: {
        maxW: { base: 'unset', lg: '120px' },
        w: { base: '50%', lg: 'unset' }
      }
    },
    {
      key: 'Bathrooms',
      variant: 'square',
      placeHolder: 'Baths',
      selectPlaceHolderValue: SpecificOrMinFilterAmount.Any,
      controlStyleProps: {
        maxW: { base: 'unset', lg: '120px' },
        w: { base: '50%', lg: 'unset' }
      }
    },
    {
      key: 'ListingAge',
      variant: 'square',
      placeHolder: 'Age',
      propertyDisplayType: PropertyDisplayType.Select,
      hideIf: {
        property: 'ViewMode',
        value: [HomeListingFilterViewMode.Landing.valueOf()]
      },
      controlStyleProps: {
        maxW: { lg: '150px' }
      },
      propertyDisplayTypeConfig: {
        items: [
          { Name: '1 Day', Value: 1 },
          { Name: '7 Days', Value: 7 },
          { Name: '14 Days', Value: 14 },
          { Name: '31 Days', Value: 31 }
        ],
        isNumeric: true
      }
    },
    {
      key: 'SortBy',
      variant: 'square',
      placeHolder: 'Sort By',
      controlStyleProps: {
        maxW: { lg: '150px' }
      },
      hideIf: {
        property: 'ViewMode',
        value: [HomeListingFilterViewMode.Landing.valueOf()]
      }
    },

    {
      key: 'ViewMode',
      variant: 'square',
      propertyDisplayType: PropertyDisplayType.Hidden,
      clientSideOnly: true
    }
  ],
  entityName: 'HomeSearchFilterForm',
  submitButtonText: ''
};
