import { FormDefinition } from '@cksoftware/react-base';
import { PropertyDisplayType } from '../../../../enums/form/PropertyDisplayType.tsx';
import { SpecificOrMinFilterAmount } from '../../../../enums/form/SpecificOrMinFilterAmount.tsx';
import { generatePriceDropdown } from '../../../../util/generatePriceDropdown.ts';
import { HomeListingFilterModel } from '../../models/HomeListingFilterModel.ts';

const lotSizeDropdown = [
  { Name: '0.25 Acres', Value: 0.25 },
  { Name: '0.5 Acres', Value: 0.5 },
  { Name: '1 Acre', Value: 1 },
  { Name: '2 Acres', Value: 2 },
  { Name: '5 Acres', Value: 5 },
  { Name: '10 Acres', Value: 10 },
  { Name: '50 Acres', Value: 50 },
  { Name: '50 Acres', Value: 50 },
  { Name: '100 Acres', Value: 100 },
  { Name: '200 Acres', Value: 200 },
  { Name: '300 Acres', Value: 300 },
  { Name: '400 Acres', Value: 400 },
  { Name: '500 Acres', Value: 500 },
  { Name: '1000 Acres', Value: 1000 }
];

export const HomeListingFilterDialogFormDefinition: FormDefinition<HomeListingFilterModel> = {
  questions: [
    {
      key: 'MinPrice',
      placeHolder: 'Min $',
      propertyDisplayType: PropertyDisplayType.Select,
      propertyDisplayTypeConfig: {
        items: generatePriceDropdown(0, 5000000, 50000),
        isNumeric: true
      }
    },
    {
      key: 'MaxPrice',
      placeHolder: 'Max $',
      propertyDisplayType: PropertyDisplayType.Select,
      propertyDisplayTypeConfig: {
        items: generatePriceDropdown(50000, 5000000, 50000),
        isNumeric: true
      }
    },
    {
      key: 'CommonInterest',
      placeHolder: 'Property Type',
      propertyDisplayType: PropertyDisplayType.Select,
      propertyDisplayTypeConfig: {
        items: [
          { Name: 'Condo/Apartment', Value: 'Condo/Strata' },
          { Name: 'Single Family', Value: 'Freehold' }
        ],
        isNumeric: false
      }
    },
    {
      key: 'SquareFootageMin',
      placeHolder: 'Min Sqft'
    },
    {
      key: 'SquareFootageMax',
      placeHolder: 'Max Sqft'
    },
    {
      key: 'Bedrooms',
      placeHolder: 'Beds',
      selectPlaceHolderValue: SpecificOrMinFilterAmount.Any
    },
    {
      key: 'Bathrooms',
      placeHolder: 'Baths',
      selectPlaceHolderValue: SpecificOrMinFilterAmount.Any
    },
    {
      key: 'ListingAge',
      placeHolder: 'Age',
      propertyDisplayType: PropertyDisplayType.Select,
      propertyDisplayTypeConfig: {
        items: [
          { Name: '1 Day', Value: 1 },
          { Name: '7 Days', Value: 7 },
          { Name: '14 Days', Value: 14 },
          { Name: '31 Days', Value: 31 }
        ],
        isNumeric: true
      }
    },
    {
      key: 'Storeys',
      placeHolder: 'Storeys',
      selectPlaceHolderValue: SpecificOrMinFilterAmount.Any
    },
    {
      key: 'LotSizeMinimum',
      placeHolder: 'Lot Size Min',
      propertyDisplayType: PropertyDisplayType.Select,
      propertyDisplayTypeConfig: {
        items: lotSizeDropdown,
        isNumeric: true
      }
    },
    {
      key: 'LotSizeMaximum',
      placeHolder: 'Lot Size Max',
      propertyDisplayType: PropertyDisplayType.Select,
      propertyDisplayTypeConfig: {
        items: lotSizeDropdown,
        isNumeric: true
      }
    }
  ],
  entityName: 'HomeSearchFilterForm',
  submitButtonText: ''
};
