import { Box, Flex, Image, Link, useColorModeValue } from '@chakra-ui/react';
import { NavLink, useNavigate } from 'react-router-dom';
import background from '../../assets/header_background.png';
import logo from '../../assets/valt_logo_white.png';
import { useValtAuth } from '../../util/useValtAuth';

export const NavBar = () => {
  const auth = useValtAuth();
  const nav = useNavigate();
  return (
    <Box
      bg={'white'}
      backgroundColor={'green.100'}
      px={0}
      borderBottom={0}
      borderStyle={'solid'}
      borderColor={useColorModeValue('gray.200', 'gray.900')}
      height={['60px', '120px']}
      zIndex={'docked'}
      width={'100%'}>
      <Image height={['60px', '120px']} pos={'absolute'} top={0} left={0} src={background} alt='Valt' />
      <Flex justifyContent={'space-between'} w={'100%'}>
        <Link
          onClick={() => {
            if (auth.isAuthenticated) {
              nav('/portal');
              return;
            }
            if (window.location.host.indexOf('localhost') >= 0) {
              nav('/');
              return;
            }
            window.location.href = 'https://www.valtup.com';
          }}
          as={Box}
          marginLeft={{ base: '10px', xl: '150px' }}
          marginTop={'10px'}
          zIndex={'modal'}>
          <Image marginTop={'auto'} height={['40px', '100px']} paddingLeft={['10px', '0px']} zIndex={'2'} top={0} left={0} src={logo} />
        </Link>

        <Box marginRight={{ base: '10px', xl: '150px' }} marginTop={{ base: '10px', xl: '45px' }}>
          {auth.isAuthenticated && (
            <>
              <Link
                color={'gold.100'}
                onClick={() => {
                  auth.logout();
                  window.location.reload();
                }}>
                {auth.user.Email}
              </Link>{' '}
              <br />
              <Link color={'gold.100'} as={NavLink} to={'/portal'}>
                Portal
              </Link>
            </>
          )}
          {!auth.isAuthenticated && (
            <Link color={'gold.100'} as={NavLink} to={'/login'}>
              Login
            </Link>
          )}
        </Box>
      </Flex>
    </Box>
  );
};
