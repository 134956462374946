import { Box, Flex } from '@chakra-ui/react';

import { Outlet } from 'react-router-dom';
import { Footer } from './footer';
import { NavBar } from './navBar';

export const MainLayout = () => {
  return (
    <Flex as='section' minHeight={'100vh'} margin={'0'} flexDirection={'column'}>
      <NavBar />
      <Box w={['100%', '100%', '80%', '80%', '80%', '60%']} maxW={'750px'} margin={'auto'} flex={1} paddingLeft={'5px'} paddingTop={'55px'} paddingRight={'5px'}>
        <Outlet />
      </Box>
      <Footer />
    </Flex>
  );
};
