import { useToast } from '@chakra-ui/react';
import { FormDefinitionForm, User, useFormDefinitionForm, useUserAdminApi } from '@cksoftware/react-base';
import { EditUserFormDefinition } from './FormModels/EditUserFormDefinition';

type EditUserFormProps<T extends User> = {
  userModel: T;
  onSaveSuccess: () => void;
  onSaveFail: () => void;
};

export const EditUserForm = <T extends User>(props: EditUserFormProps<T>) => {
  const formDefForm = useFormDefinitionForm(EditUserFormDefinition, props.userModel);
  const authAdmin = useUserAdminApi();
  const toast = useToast();

  return (
    <div>
      <FormDefinitionForm
        formDefinitionForm={formDefForm}
        onSubmit={async (model) => {
          try {
            await authAdmin.updateUser(model);
            toast({
              title: 'User Updated',
              description: 'User information updated.'
            });
            props.onSaveSuccess();
          } catch (e) {
            toast({
              title: 'User Update Failed',
              description: 'User update has failed.'
            });
            props.onSaveFail();
          }
        }}
      />
    </div>
  );
};
