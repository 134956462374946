import { Box, Button, Flex } from '@chakra-ui/react';
import { PropertyListValueModel, QuestionRenderer, useFormDefinitionForm } from '@cksoftware/react-base';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { MultiAddressField } from '../../../../components/MultiAddressField';
import { JsonViewer } from '../../../../components/misc/JsonView';
import { useIsMobile } from '../../../../util/useIsMobile';
import { HomeListingFilterModel } from '../../models/HomeListingFilterModel';
import { HomeListingFilterDialog } from './HomeListingFilterDialog';
import { HomeListingFilterFormDefinition } from './HomeListingFilterFormDefinition';
import { MobileHomeListingFilterFormDefinition } from './MobileHomeListingFilterFormDefinition';

type HomeListingFilterFormProps = {
  filterModel: HomeListingFilterModel;
  onSearch: (model: HomeListingFilterModel) => void;
  showSearchButton: boolean;
  showFilterButton: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const HomeListingFilterForm = (props: HomeListingFilterFormProps) => {
  const isMobile = useIsMobile();
  const formDefinition = isMobile ? MobileHomeListingFilterFormDefinition : HomeListingFilterFormDefinition;
  const entityForm = useFormDefinitionForm(formDefinition, null, props.filterModel);

  const onPlaceSelected = (val: PropertyListValueModel, place: google.maps.places.PlaceResult) => {
    if (val?.Value && val.Value.toString().indexOf('MLS') >= 0) {
      entityForm.methods.setValue('SelectedAddress', undefined);
      entityForm.methods.setValue('SearchString', undefined);
      entityForm.methods.setValue('Lat', undefined);
      entityForm.methods.setValue('Lng', undefined);
      entityForm.methods.setValue('MlsNumber', val.Value.toString());
      entityForm.methods.setValue('HasSelectedAddress', true);
      return;
    }

    if (!place) {
      entityForm.methods.setValue('SelectedAddress', undefined);
      entityForm.methods.setValue('SearchString', undefined);
      entityForm.methods.setValue('Lat', undefined);
      entityForm.methods.setValue('Lng', undefined);
      entityForm.methods.setValue('HasSelectedAddress', false);
      entityForm.methods.setValue('MlsNumber', undefined);

      return;
    }

    let street = '';

    place!.address_components!.forEach((component) => {
      if (component.types.includes('street_number')) {
        street = `${component.long_name} `;
      }
      if (component.types.includes('route')) {
        street += component.long_name;
      }
    });
    entityForm.methods.setValue('SelectedAddress', street);
    entityForm.methods.setValue('SearchString', place.formatted_address);
    entityForm.methods.setValue('HasSelectedAddress', true);
    entityForm.methods.setValue('Lat', place.geometry.location.lat());
    entityForm.methods.setValue('Lng', place.geometry.location.lng());
  };

  useEffect(() => {
    const watcher = entityForm.methods.watch(() => {
      if (!props.showSearchButton) {
        props.onSearch(entityForm.methods.getValues());
      }
    });
    return () => watcher.unsubscribe();
  }, [entityForm.methods.watch, props.showSearchButton]);

  if (!props.filterModel || entityForm.query.isLoading) {
    return null;
  }

  return (
    <>
      <HomeListingFilterDialog
        filterModel={props.filterModel}
        onSearch={(model) => {
          props.onSearch(model);
          props.onClose();
        }}
        showSearchButton={true}
        isOpen={props.isOpen}
        onClose={props.onClose}
      />
      <FormProvider {...entityForm.methods}>
        {location.hostname === 'localhost' && (
          <Box pos={'fixed'} top={'40px'} left={'400px'} cursor={'pointer'} zIndex={'modal'}>
            <JsonViewer object={entityForm.methods.getValues()} />
          </Box>
        )}
        <form
          style={{ width: '100%' }}
          onSubmit={entityForm.methods.handleSubmit(
            async (model) => {
              props.onSearch(model);
            },
            (e) => {
              console.log(e);
            }
          )}>
          <Flex gap={0} w={'100%'} flexDir={{ base: 'row' }} rowGap={'5px'} justifyContent={'flex-end'}>
            <MultiAddressField
              methods={entityForm.methods}
              addressSelectedField='HasSelectedAddress'
              selectedAddressField='SearchString'
              onSelectedOption={onPlaceSelected}
              placeHolder='City, address, or MLS Number'
            />
            {formDefinition.questions.map((value, index) => {
              return (
                <QuestionRenderer key={index} fieldPadding='4px' labelProps={{ margin: 0, padding: 0 }} formQuestionModel={value} entityPropertyModels={entityForm.query.data.FormPropertyModels} />
              );
            })}

            {props.showFilterButton && (
              <Button width={'75px'} onClick={props.onOpen}>
                <FontAwesomeIcon style={{ paddingRight: '5px' }} icon={faFilter} />
              </Button>
            )}

            {props.showSearchButton && (
              <Button w={'120px'} aria-label={'search'} type='submit'>
                Search
              </Button>
            )}
          </Flex>
        </form>
      </FormProvider>
    </>
  );
};
