import { Box, Flex, Heading } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { HomeListingFilterForm } from '../HomeListings/HomeListingFilters/HomeListingFilterForm.tsx';
import { HomeListingMap } from '../HomeListings/HomeListingMap/HomeListingMap.tsx';
import { HomeListingFilterModel } from '../models/HomeListingFilterModel.ts';

type LandingProps = {
  filterModel: HomeListingFilterModel;
  onSearch: (model: HomeListingFilterModel) => void;
};

export const Landing = (props: LandingProps) => {
  const [size, setSize] = useState(undefined);
  useEffect(() => {
    setSize(`${window.innerWidth}x${window.innerHeight}`);
  }, []);
  return (
    <Box w={['100%', '100%', '100%', '100%', '100%', '80%']} maxW={'1600px'} margin={'auto'} paddingTop={['50px', '175px']} paddingLeft={'10px'} paddingRight={'10px'} textAlign={'center'}>
      {size != undefined && (
        <Box pos={'absolute'} top={0} left={0} w={'100vw'} h={'100vh'} zIndex={-1} opacity={0.2} backgroundColor={'black'}>
          <HomeListingMap
            data={[]}
            hoverItem={undefined}
            onHoverMapMarker={() => {}}
            onMapMove={() => {}}
            zoom={13}
            center={{
              lat: 51.04175624622379,
              lng: -114.06586818223111
            }}
          />
        </Box>
      )}
      <Flex flexDir={'column'} alignItems={'center'}>
        <Heading>Let's find your next home</Heading>
        <Box w={{ base: '100%', lg: '70%' }}>
          <HomeListingFilterForm showFilterButton={false} filterModel={props.filterModel} onSearch={props.onSearch} showSearchButton={true} isOpen={false} onClose={undefined} onOpen={undefined} />
        </Box>
      </Flex>
    </Box>
  );
};
