import { FormDefinition } from '@cksoftware/react-base';
import { PropertyDisplayType } from '../../../enums/form/PropertyDisplayType';
import { EmailRequestFormModel } from './EmailRequestForm';

export const EmailRequestFormDefinition: FormDefinition<EmailRequestFormModel> = {
  questions: [
    { key: 'Message', label: 'Message', propertyDisplayType: PropertyDisplayType.TextArea },
    { key: 'PreferredContactMethod', label: 'Preferred Contact Method' }
  ],
  entityName: 'EmailRequestForm',
  submitButtonText: 'Submit'
};
