import { Box, Center, Flex } from '@chakra-ui/react';
import { GenericWizardStep, StepContainer, Wizard, WizardFormContainer, useFormModelQuery, useWizardState } from '@cksoftware/react-base';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingSpinner } from '../../components/misc/LoadingSpinner';
import { NewContactWizardDefinition } from './ClientOnboardingFormDefinition';
import { submitNewClientForm } from './api/submitNewClientForm';
import { NewClientForm } from './models/NewContactForm';

const ClientOnboardingFunnel = () => {
  const entityName = 'NewClientForm';
  const entityDefinition = useFormModelQuery(entityName);
  const wizardStore = useWizardState();
  const steps: Array<StepContainer> = [];
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isDevData = !!searchParams.get('devData');
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (model: NewClientForm) => {
    setIsLoading(true);
    await submitNewClientForm(model);
    setIsLoading(false);
    navigate('/funnel-complete');
  };

  useEffect(() => {
    wizardStore.setTotalSteps(steps.length - 1);
  }, [entityDefinition.isFetched, steps.length]);

  if (entityDefinition.isFetched && entityDefinition.data !== undefined) {
    NewContactWizardDefinition.steps.forEach((value, index) => {
      steps.push({
        component: (
          <WizardFormContainer<NewClientForm>
            onStepBackward={NewContactWizardDefinition.onStepBackward}
            onStepForward={NewContactWizardDefinition.onStepForward}
            steps={NewContactWizardDefinition.steps}
            formModel={entityDefinition.data}
            stepDefinition={NewContactWizardDefinition.steps[index]}
            defaultData={isDevData && index == 0 ? NewContactWizardDefinition.defaultData : null}
            onSubmit={onSubmit}>
            {value.customRenderer && <value.customRenderer stepDefinition={NewContactWizardDefinition.steps[index]} properties={entityDefinition.data.FormPropertyModels} />}
            {!value.customRenderer && <GenericWizardStep stepDefinition={NewContactWizardDefinition.steps[index]} properties={entityDefinition.data.FormPropertyModels} />}
          </WizardFormContainer>
        ),
        stepName: value.stepName,
        progressStepIndex: value.progressStepIndex
      });
    });

    return (
      <Flex direction={'column'} flex='1' w={'100%'} maxW={'100%'}>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <Center>
            <Box w={'100%'}>
              <Wizard steps={steps} progressModel={NewContactWizardDefinition.progressBar} />
            </Box>
          </Center>
        )}
      </Flex>
    );
  }
  return <LoadingSpinner />;
};

export default ClientOnboardingFunnel;
